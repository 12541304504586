@charset 'UTF-8';
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
►►► Style/components/buttons
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

.button {
  display: inline-block;
  padding: 1.4rem 2rem;
  appearance: none;
  box-shadow: none;
  font-family: $ff-default;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.833;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color $animation,
              color $animation;

  //  Colors
  //  ───────────────────────────────────
  &.primary {
    background-color: clr(primary);
    color: clr(white);
    &:hover,
    &:focus,
    &:active {
      background-color: clr(secondary);
    }
  }

  &.secondary {
    background-color: clr(secondary);
    color: clr(white);
    &:hover,
    &:focus,
    &:active {
      background-color: clr(primary);
    }
  }

  &.tertiary {
    background-color: clr(tertiary);
    color: clr(white);
    &:hover,
    &:focus,
    &:active {
      background-color: darken(clr(tertiary), 5%);
    }
  }

  &.gradient01 {
    background-image: linear-gradient(to right, clr(primary), clr(secondary));
    color: clr(white);
  }

  &.gradient02 {
    background-image: linear-gradient(to right, clr(secondary), clr(primary));
    color: clr(white);
  }

  &.white {
    background-color: clr(primary);
    color: clr(white);
    &:hover,
    &:focus,
    &:active {
      background-color: clr(secondary);
    }
  }

  //  Sizes
  //  ───────────────────────────────────
  &.is-full {
    width: 100%;
  }
}
