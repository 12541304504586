@charset 'UTF-8';
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
►►► Style/layout/page
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

.page-contents {
  //  Alignement text
  //  ───────────────────────────────────
  .justify {
    text-align: justify;
  }

  .left {
    text-align: left;
  }

  .center {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;

    ol,
    ul {
      padding-left: 0;
    }
  }

  @include mq(lg-up) {
    .right {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      text-align: right;

      ol,
      ul {
        padding-left: 0;
      }
    }
  }
}

//  Backgrounds
//  ───────────────────────────────────
.page-section {
  padding: 5rem 0;
  @include mq(md-up) {
    padding: 8rem 0;
  }

  &.color_01 {
    .p, p{
      color: clr(white);
    }
    .h1, h1{
      color: clr(white);
    }
    .h2, h2{
      color: clr(white);
    }
    .h3, h3{
      color: clr(white);
    }
    .h6, h6{
      color: clr(white);
    }
    .li, li{
      color: clr(white);
    }
    .button {
      color: clr(primary);
      background-color: white;
      &:hover,
      &:focus,
      &:active {
        background-color: clr(secondary);
      }
    }
  }

  &.color_02 {
    .p, p{
      color: clr(white);
    }
    .h1, h1{
      color: clr(white);
    }
    .h2, h2{
      color: clr(white);
    }
    .h3, h3{
      color: clr(white);
    }
    .h6, h6{
      color: clr(white);
    }
    .li, li{
      color: clr(white);
    }
    .button {
      color: clr(secondary);
      background-color: white;
      &:hover,
      &:focus,
      &:active {
        background-color: clr(primary);
      }
    }
  }

  &.gradient_01 {
    .p, p{
      color: clr(white);
    }
    .h1, h1{
      color: clr(white);
    }
    .h2, h2{
      color: clr(white);
    }
    .h3, h3{
      color: clr(secondary);
    }
    .h6, h6{
      color: clr(white);
    }
    .li, li{
      color: clr(white);
    }
    .button {
      color: clr(primary);
      background-color: white;
      &:hover,
      &:focus,
      &:active {
        background-color: clr(secondary);
      }
    }
  }

  &.gradient_02 {
    .p, p{
      color: clr(white);
    }
    .h1, h1{
      color: clr(white);
    }
    .h2, h2{
      color: clr(white);
    }
    .h3, h3{
      color: white;
    }
    .h6, h6{
      color: clr(white);
    }
    .li, li{
      color: clr(white);
    }
    .button {
      color: clr(primary);
      background-color: white;
      &:hover,
      &:focus,
      &:active {
        background-color: clr(secondary);
      }
    }
  }

  &.white {
    .p, p{
      color: clr(primary);
    }
    .h1, h1{
      color: clr(primary);
    }
    .h2, h2{
      color: clr(primary);
    }
    .h3, h3{
      color: clr(secondary);
    }
    .h6, h6{
      color: clr(secondary);
    }
    .li, li{
      color: clr(primary);
    }
    .button {
      color: white;
      background-color: clr(primary);
      &:hover,
      &:focus,
      &:active {
        background-color: clr(secondary);
      }
    }
  }

  &.color_01,
  &.color_02,
  &.gradient_01,
  &.gradient_02 {
    
    .slider {

      .swiper-button-prev,
      .swiper-container-rtl .swiper-button-next {
        background-image: svg-inline(arrow-left-white);
      }

      .swiper-button-next,
      .swiper-container-rtl .swiper-button-prev {
        background-image: svg-inline(arrow-right-white);
      }
    }
  }

  &.color_01 {
    background-color: clr(primary);
  }

  &.color_02 {
    background-color: clr(secondary);
  }

  &.gradient_01 {
    background-image: linear-gradient(to bottom, clr(primary), clr(secondary));
  }

  &.gradient_02 {
    background-image: linear-gradient(to bottom, clr(secondary), clr(primary));
  }

  &.white {
    background-color: clr(white);

    + .white {
      border-top: 1px solid clr(secondary);
    }
  }

  hr {
    border-color: clr(secondary);
  }

  blockquote::before {
    background-color: clr(secondary);
  }

  //  Builders
  //  ───────────────────────────────────

  // &.builder-cards {

  //   .container:nth-child(2) {
  //     margin-top: $vr * 2;

  //     @include mq(lg-up) {
  //       > div > div:nth-child(n+6) {
  //         margin-top: $vr;
  //       }
  //     }
  //     @include mq(md-low) {
  //       > div > div:nth-child(n+2) {
  //         margin-top: $vr;
  //       }
  //     }
  //   }
  // }

  // Builder - cards

  &.builder-cards {
    @include mq(lg-up) {
      .is-2 {

        > div:nth-child(n+3) {
          margin-top: $vr;
        }

        .image {
          height: 50rem;
        }
      }

      .is-3 {

        > div:nth-child(n+4) {
          margin-top: $vr;
        }

        .image {
          height: 40rem;
        }
      }

      .is-4 {

        > div:nth-child(n+5) {
          margin-top: $vr;
        }

        .image {
          height: 30rem;
        }
      }
    }
    @include mq(md) {
      > div {

        > div:nth-child(n+3) {
          margin-top: $vr;
        }
      }
    }
    @include mq(sm-low) {
      > div {

        > div:nth-child(n+1) {
          margin-top: $vr;
        }
      }
    }

    .builder-cards--list,
    .builder-cards--cta {
      margin-top: $vr * 2;
    }

    .card {
      padding: 0 $vr;

      a {
        display: block;
      }
    }

    .image {
      @include mq(md-low) {
        height: 30rem;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .image + .details {
      margin-top: 2rem;
    }

    .dp-block:hover {
      color: clr(tertiary);
    }

    &.color_01,
    &.color_02,
    &.gradient_01,
    &.gradient_02 {

      .image {
        background-color: clr(white);
      }

      h3 {
        color: clr(white);
      }
    }

    &.white {

      .image {
        background-color: clr(primary);
      }

      h3 {
        color: clr(primary);
      }
    }
  }

  // Builder - complex
  &.builder-complex {

    @include mq(lg-up) {
      > div > div > div {
        display: flex;
        align-items: center;
      }
    }
      
    &.color_02 {
      .p, p{
        color: clr(white);
      }
      .h1, h1{
        color: clr(white);
      }
      .h2, h2{
        color: clr(white);
      }
      .h3, h3{
        color: clr(white);
      }
      .h6, h6{
        color: clr(white);
      }
      .li, li{
        color: clr(white);
      }
      .button {
        color: clr(secondary);
        background-color: white;
        &:hover,
        &:focus,
        &:active {
          background-color: clr(primary);
        }
      }
    }
  }

  // Builder - file
  &.builder-file {

    .file {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      p + .button {
        margin-top: $vr;
      }
    }
  }

  // Builder - image
  &.builder-image {
    padding: 0;

    figure {

      a,
      img {
        width: 100%;
      }
    }
  }

  // Builder - pages
  &.builder-pages {
    @include mq(lg-up) {
      .is-2 {

        > div:nth-child(n+3) {
          margin-top: $vr;
        }

        .image {
          height: 50rem;
        }
      }

      .is-3 {

        > div:nth-child(n+4) {
          margin-top: $vr;
        }

        .image {
          height: 40rem;
        }
      }

      .is-4 {

        > div:nth-child(n+5) {
          margin-top: $vr;
        }

        .image {
          height: 30rem;
        }
      }
    }
    @include mq(md) {
      > div {

        > div:nth-child(n+3) {
          margin-top: $vr;
        }
      }
    }
    @include mq(sm-low) {
      > div {

        > div:nth-child(n+1) {
          margin-top: $vr;
        }
      }
    }

    .builder-pages--list,
    .builder-pages--cta {
      margin-top: $vr * 2;
    }

    .page {
      padding: 0 $vr;

      a {
        display: block;
      }
    }

    .image {
      @include mq(md-low) {
        height: 30rem;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .image + .details {
      margin-top: 2rem;
    }

    .dp-block:hover {
      color: clr(tertiary);
    }

    &.color_01,
    &.color_02,
    &.gradient_01,
    &.gradient_02 {

      .image {
        background-color: clr(white);
      }

      h3 {
        color: clr(white);
      }
    }

    &.white {

      .image {
        background-color: clr(primary);
      }

      h3 {
        color: clr(primary);
      }
    }
  }
}

