@charset 'UTF-8';
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
►►► Style/components/forms
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

// Label
// ───────────────────────────────────
label {
  margin-bottom: 0.5rem;
	display: block;
  color: clr(primary);
  font-family: $ff-heading;
  font-size: 1.2rem;
  font-weight: 700;
  text-transform: uppercase;

  cursor: pointer;
}

.field-info {
	display: block;
	margin-top: 1rem;
	margin-bottom: 0;
	font-size: 1.2rem;
}

.field {
	margin-bottom: 3rem;
}

.form-group {
	display: flex;
	flex-wrap: wrap;

	> .field {
		flex: 1;
		&:not(.one):nth-of-type(odd) {
			padding-right: 1.5rem;
		}
		&:nth-of-type(even) {
			padding-left: 1.5rem;
		}
	}

	.custom-checkbox {

		label	{
			color: clr(primary);
		}
	}
}

input, textarea, select, button {
	appearance: none;
}

input[type="submit"],
input[type="email"],
input[type="password"],
input[type="text"],
input[type="number"],
input[type="tel"],
textarea,
select {
	display: block;
	width: 100%;
	padding: 1rem 1.4rem;
	border-radius: 0;
	background-color: transparent;
  border: 1px solid clr(grey);
	font-family: $ff-default;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.567;
	color: clr(primary);
	transition: border-color $animation;
	&:hover, &:focus {
		border-color: clr(primary);
	}
}

textarea {
	resize: vertical;
	min-height: 15rem;
}

input[type="submit"] {
  padding: 1.4rem 2rem;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.833;
  text-transform: uppercase;
}

.field-icon {
	position: relative;
	input {
		padding-right: 6.6rem;
	}
	span {
		position: absolute;
		top: 0;
		right: 0;
		width: 4.6rem;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 0;
		border: 1px solid clr(primary);
		background-color: clr(white);
	}
}

// Input select
// ───────────────────────────────────
select {
	background-color: transparent;
  background-image: svg-inline(arrow-down);
	background-repeat: no-repeat;
	background-size: 1.5rem;
  background-position: center right 2rem;
	padding: 1rem 4rem 1rem 2rem;
  cursor: pointer;

  &::-ms-expand {
    display: none;
  }

  &:disabled {
    cursor: default;
  }
}

// Input checkbox & input radio
// ───────────────────────────────────
.custom-checkbox,
.custom-radio {
	input[type="checkbox"],
	input[type="radio"] {
		position: absolute;
		height: 0;
    opacity: 0;

		+ label {
			display: block;
			position: relative;
			padding-left: 2.5rem;
      text-transform: none;
			font-size: 1.6rem;
			font-weight: 400;
			line-height: 1.4;
			transition: color $animation;
			user-select: none;
      cursor: pointer;

			&:before,
			&::after {
				content: "";
				position: absolute;
				top: 4px;
				left: 0;
				width: 1.4rem;
				height: 1.4rem;
      }

			&::before {
				border: 1px solid clr(primary);
      }

			&::after {
				width: 0.8rem;
				height: 0.8rem;
				top: 8px;
				left: 4px;
				background-color: clr(primary);
				opacity: 0;
				transition: opacity $animation;
			}
		}

		&:checked {
			+ label {
				&::after {
					opacity: 1;
				}
			}
		}
	}

	+ .error {
		padding-left: 4rem;
	}
}

// Input radio
// ───────────────────────────────────
.custom-radio {
	display: flex;
	align-items: center;
	input[type="radio"] {
		+ label {
			&:before,
			&::after {
				border-radius: 50%;
			}
		}
	}
}

.grecaptcha-badge  { visibility: hidden; }

.honeypot  {
  position: absolute;
  left: -9999px;
}

#grecaptcha  {
  margin-bottom: 2.5rem;
}
