@charset 'UTF-8';
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
►►► Style/globales/helpers
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

//  Alignement text
//  ───────────────────────────────────
.text-left { text-align: left !important; }
.text-center { text-align: center !important; }
.text-right { text-align: right !important; }
.text-justify { text-align: justify !important; }

@include mq(lg-up) {
  .md-text-right { text-align: right !important; }
}

//  Colors
//  ───────────────────────────────────
@each $color in $colors {
  $c_name: nth($color, 1);
  $c_hex: nth($color, 2);

  .text-#{$c_name} {
    &, p, a { color: $c_hex !important; }
  }
}

//  Backgrounds
//  ───────────────────────────────────
@each $color in $colors {
  $c_name: nth($color, 1);
  $c_hex: nth($color, 2);

  .bg-#{$c_name} {
    background-color: $c_hex;
  }
}
.bg-gradient-primary {
  background-image: linear-gradient(to bottom, clr(primary), clr(secondary));
}
.bg-gradient-secondary {
  background-image: linear-gradient(to bottom, clr(secondary), clr(primary));
}

.uppercase {
  text-transform: uppercase;
}

//  Ratio
//  ───────────────────────────────────
[class*="ratio"]{
  position: relative;

  img:not(.no-cover),
  video:not(.no-cover){
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &:before {
    display: block;
    content: "";
    width: 100%;
  }

  .ratio-content{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    img{
      margin: 0 auto;
    }
  }
}

.ratio-1_1{
  &:before {
    padding-top: (1 / 1) * 100%;
  }
}

.ratio-3_4{
  &:before {
    padding-top: (4 / 3) * 100%;
  }
}

.ratio-4_3{
  &:before {
    padding-top: (3 / 4) * 100%;
  }
}

.mg-auto{
  margin-left: auto;
  margin-right: auto;
}
