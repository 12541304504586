@charset 'UTF-8';
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
►►► Style/layout/footer
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

footer {
  &.color_01 {
    background-color: clr(primary);

    h6, .h6{
      color: white;
    }

    p, .p{
      color: white;
    }
  }
  &.color_02 {
    background-color: clr(secondary);
  }
  &.color_01,
  &.color_02 {

    * {
      color: clr(white);
    }

    svg path {
      fill: clr(white);
    }

    .footer-bottom p,
    .footer-bottom a {
      color: rgba(clr(white), 0.5);
    }
  }
  &.white {
    background-color: clr(white);

    *:not(.h6) {
      color: clr(primary);
    }

    svg path {
      fill: clr(primary);
    }

    .footer-bottom p,
    .footer-bottom a {
      color: rgba(clr(primary), 0.5);
    }
  }

  .footer-widgets {
    padding: 6rem 0;
    border-top: 1px solid clr(white);

    .logo {

      img {
        width: 100%;
        height: auto;
      }

      @include mq(md-low) {
        margin-bottom: 3rem;

        svg {
          max-width: 12rem;
        }
      }
    }

    .informations {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-between;
      @include mq(md-up) {
        flex-direction: row;
      }
      @include mq(sm-low) {
        flex-direction: row;
      }
      @include mq(xs) {
        flex-direction: column;
      }

      .information {
        width: 30%;
        flex-basis: 30%;
        @include mq(sm-up) {
          &:nth-child(n+4) {
            margin-top: $vr;
          }
        }

        @include mq(xs) {
          width: 100%;
          flex-basis: 100%;
          &:not(:first-child) {
            margin-top: $vr;
          }
        }

        .h6 {
          margin-bottom: $vr / 6;
        }

        p:not(.h6) {
          font-size: 1.2rem;
          line-height: 1.3333;
        }

        nav {

          a {

            &:not(:first-child) {
              margin-left: 0.5rem;
            }

            svg {
              margin-right: 0.2rem;
              height: 1.3rem;
              width: auto;
            }

            span {
              font-size: 1.2rem;
              line-height: 1.8333;
            }
          }
        }
      }
    }
  }

  .footer-bottom {
    padding: 4rem 0;
    border-top: 1px solid clr(white);

    &-inner {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      @include mq(md-up) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
      @include mq(sm-low) {
        flex-direction: column-reverse;
        align-items: flex-start;
        justify-content: space-between;
      }

      p {
        font-size: 1.4rem;
        line-height: 1.1428;
        @include mq(sm-low) {
          margin-top: 2rem;
        }
      }

      nav {

        a {
          font-size: 1.4rem;
          line-height: 1.1428;
          transition: color $animation;

          &:hover {
            transition: color $animation;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
