@charset 'UTF-8';
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
►►► Style/components/slider
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

.slider {

  ul, li {
    margin-bottom: 0;
  }

  li {
    p {
      padding-top: $vr;
    }
  }

  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next,
  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    margin: 0;
    width: 2rem;
    height: 2rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 2rem;
    transform: translateY(-50%);
  }

  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    left: 0;
    background-image: svg-inline(arrow-left);
  }

  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    right: 0;
    background-image: svg-inline(arrow-right);
  }

  .swiper-wrapper {
    align-items: center;
  }

  .swiper-slide {
    text-align: center;

    img {
      margin: 0 auto
    }
  }

  @include mq(lg-up) {
    .swiper-slide {
      padding: 0 $gl-colWidth;
    }

    &.small {
      .swiper-slide {
        padding: 0 $gl-colWidth/2;
      }
    }
  }
}
