@charset 'UTF-8';
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
►►► Style/components/accordion
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

.accordion {
  margin-top: 5rem;

  .accordion--item {
    &:not(:first-child) {
      margin-top: 5rem;
    }
    &.active {

      .question {

        svg {
          transform: rotate(45deg);
          transition: transform $animation;
        }
      }

      .answer {
        opacity: 1;
        pointer-events: auto;
        transition: height $animation, opacity $animation;

        ol,
        ul,
        p {
          text-align: justify;
        }
      }
    }

    .question {
      position: relative;
      padding-right: $vr;
      cursor: pointer;

      @include mq(md-up) {
        padding-right: $vr * 2;
      }

      svg {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto 0;
        width: 2.5rem;
        height: 2.5rem;
        transition: transform $animation;
        @include mq(md-up) {
          width: 3.5rem;
          height: 3.5rem;
        }
      }

      .h3 {
        flex: 1;
        font-size: 2.4rem;
        font-weight: 400;
        line-height: 1.1666;
      }
    }

    .answer {
      padding-top: 2rem;
      padding-right: $vr;
      height: 0;
      opacity: 0;
      pointer-events: none;
      transition: height $animation, opacity $animation;
      @include mq(md-up) {
        padding-right: $vr * 2;
      }
    }
  }
}
