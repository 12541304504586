@charset 'UTF-8';
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
►►► Style/globales/icons
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

@svg-load arrow-down url(../sprite/arrow-down.svg) {
	path, rect, polygon, circle{ fill: #{clr(primary)} }
}

@svg-load arrow-left url(../sprite/arrow-left.svg) {
	path, rect, polygon, circle{ fill: #{clr(primary)} }
}

@svg-load arrow-right url(../sprite/arrow-right.svg) {
	path, rect, polygon, circle{ fill: #{clr(primary)} }	
}

@svg-load arrow-left-white url(../sprite/arrow-left.svg) {
	path, rect, polygon, circle{ fill: #{clr(white)} }
}

@svg-load arrow-right-white url(../sprite/arrow-right.svg) {
	path, rect, polygon, circle{ fill: #{clr(white)} }
}

.icon {
	vertical-align: middle;
	width: 2rem;
  height: 2rem;

	&.small {
		width: 1.4rem;
		height: 1.4rem;
  }

	&.large {
		width: 7.5rem;
		height: 7.5rem;
	}
}
